<script>
export default {
  name: "CodeVerificationForm",
  props: {
    mfaAccessToken: {
      type: String,
      required: true
    },
    provider: {
      type: Object,
      required: true
    },
    scopes: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    verificationCode: "",
  }),
  methods: {
    async verifyCode() {
      this.$vs.loading();
      try {
        const {data} = await axios.post("/api/auth/mfa/verify-code", {
          code: this.verificationCode,
          challenge: this.provider.id,
          scopes: this.scopes
        }, {
          headers: {
            Authorization: `Bearer ${this.mfaAccessToken}`
          }
        });
        this.$emit("onSuccess", data);
      } finally {
        this.$vs.loading.close();
      }
    },
    resentCodeVerification() {
      alert("Reenviar código");
    },
    goBack() {
      this.$emit("onGoBack");
    }
  }
}
</script>

<template>
  <div v-else>
    <div class="mb-5">
      <h2>{{provider.verifyCodeTitle}}</h2>
      <p class="mt-2">{{provider.verifyCodeSubTitle}}</p>
    </div>
    <div class="flex flex-col gap-2">
      <vs-input
          class="w-full"
          size="large"
          v-model="verificationCode"
          label="Código de verificación"
          type="text"
      />

      <small>Reenviar código en 00:41</small>
      <vs-button @click="verifyCode" class="mt-5" color="primary">Verificar</vs-button>
      <div class="flex gap-4">
        <vs-button
            :disabled="true"
            @click="resentCodeVerification" class="mt-5" color="black">Reenviar código</vs-button>
        <vs-button @click="goBack" class="mt-5" color="black">Elegir otro método</vs-button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>