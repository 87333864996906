<script>
export default {
  name: "MfaSelector",
  props: {
    hasGoogleAuthConfigured: {
      type: Boolean,
      default: false
    },
    customerPhoneNumber: {
      type: String,
      default: null
    },
    customerEmailAddress: {
      type: String,
      default: null
    },
    mfaAccessToken: {
      type: String,
      required: true
    },
    providerAvailable: {
      type: Array,
      required: []
    }
  },
  data: () => ({
    mfaProviders: [],
  }),
  mounted() {
    this.mfaProviders.push(
        {
          id: "google-authenticator",
          name: "Google Authenticator",
          icon: "fa fa-qrcode",
          verifyCodeTitle: "Ingresa el código obtenido de Google Authenticator",
          verifyCodeSubTitle: "Es un código de 6 dígitos que cambia cada 30 segundos.",
          canShow: () => {
            return this.hasGoogleAuthConfigured;
          },
          getTarget: () => {
            return '';
          },
          description: "Escanea el código QR con la aplicación Google Authenticator",
        },
        {
          id: "sms",
          name: "SMS",
          verifyCodeTitle: "Ingresa el código que te enviamos por SMS",
          verifyCodeSubTitle: "Es un código de 6 dígitos enviado al teléfono terminado en %phone%.",
          icon: "fa fa-mobile",
          canShow: () => {
            return this.customerPhoneNumber !== null;
          },
          description: "Recibirás un mensaje de texto con un código de verificación",
          getTarget: () => {
            return this.customerPhoneNumber;
          }
        },
        {
          id: "email",
          name: "Correo Electrónico",
          icon: "fa fa-envelope",
          verifyCodeTitle: "Ingresa el código que te enviamos por correo electrónico",
          verifyCodeSubTitle: "Es un código de 6 dígitos enviado al correo %email%.",
          canShow: () => {
            return this.customerEmailAddress !== null;
          },
          description: "Recibirás un correo electrónico con un código de verificación",
          getTarget: () => {
            return this.customerEmailAddress;
          }
        },
        {
          id: "whatsapp",
          name: "WhatsApp",
          verifyCodeTitle: "Ingresa el código que te enviamos por WhatsApp",
          verifyCodeSubTitle: "Es un código de 6 dígitos enviado al teléfono terminado en %phone%.",
          canShow: () => {
            return this.customerPhoneNumber !== null;
          },
          icon: "fa fa-whatsapp",
          description: "Recibirás un mensaje de WhatsApp con un código de verificación",
          getTarget: () => {
            return this.customerPhoneNumber;
          }
        }
    );
  },
  methods: {
    selectProviderHandler(mfaProvider) {
      this.$emit("onSelectProvider", mfaProvider);
    },
  }
}
</script>

<template>
  <div>
    <h3 class="mb-10 mt-5">Elige un método de verificación para confirmar que la cuenta te pertenece</h3>
    <div
        v-if="mfaProvider.canShow()"
        @click="selectProviderHandler(mfaProvider)"
        class="hover:bg-grey-light rounded-md px-5 py-2 hover:shadow-md cursor-pointer"
        v-for="mfaProvider in mfaProviders"
    >
      <div class="gap-4 flex items-center justify-between">
        <div class="bg-primary text-white px-5 py-4 flex items-center justify-center  rounded-full">
          <i :class="mfaProvider.icon"></i>
        </div>
        <div class="two-factor-description">
          <h4>{{ mfaProvider.name }}</h4>
          <p>{{ mfaProvider.description }}</p>
        </div>
        <div class="arror">
          <i class="fa fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>