<script>
import CodeVerificationForm from "@components/auth/mfa/CodeVerificationForm.vue";
import MfaSelector from "@components/auth/mfa/MfaSelector.vue";

export default {
  name: "MfaDialog",
  components: {MfaSelector, CodeVerificationForm},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    scopes: {
      type: Array,
      required: true
    },
    hasGoogleAuthConfigured: {
      type: Boolean,
      default: false
    },
    customerPhoneNumber: {
      type: String,
      default: null
    },
    customerEmailAddress: {
      type: String,
      default: null
    },
    mfaAccessToken: {
      type: String,
      required: true
    },
  },
  data: () => ({
    selectedProvider: null
  }),
  methods: {
    selectProviderHandler(mfaProvider) {
      this.selectedProvider = mfaProvider;
      //mfaProvider.action();
      this.requestVerificationCode(mfaProvider)
    },
    async requestVerificationCode(mfaProvider) {
      this.$vs.loading();
      try {
        await axios.post("/api/auth/mfa/request-code", {
          challenge: this.selectedProvider.id,
          target: mfaProvider.getTarget()
        }, {
          headers: {
            Authorization: `Bearer ${this.mfaAccessToken}`
          }
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    mfaSuccessHandler(ev) {
      this.$emit("onMfaSuccess", ev);
    }
  }
}
</script>

<template>
  <vs-popup :active.sync="active" title="VERIFICACIÓN EN 2 PASOS">
    <div class="px-20 mb-10">
      <div>
        <mfa-selector
            @onSelectProvider="selectProviderHandler"
            v-if="selectedProvider === null"
            :customer-email-address="customerEmailAddress"
            :customer-phone-number="customerPhoneNumber"
            :has-google-auth-configured="hasGoogleAuthConfigured"
            :mfa-access-token="mfaAccessToken"
        />

        <code-verification-form
            v-else
            :scopes="scopes"
            :mfa-access-token="mfaAccessToken"
            @onGoBack="selectedProvider = null"
            @onSuccess="mfaSuccessHandler"
            :provider="selectedProvider" />
      </div>
    </div>
  </vs-popup>
</template>

<style scoped>

</style>